'use strict';

import Logo from './Logo';
import Modal from './Modal';
import gsap from 'gsap'

const Header = {
    el: null,
    isOpened: false,
    isArrow: false,
    toggle: null,
    menu: null,
    links: [],
    initialColor: null,
    prevScroll: null,
    prevWidth: null,
    green: '#00855B',
    init: function () {
        gsap.defaults({ ease: 'power2.inOut', duration: 0.4 })
        Header.el = document.querySelector('.header');

        if (Header.el) {
            Header.toggle = Header.el.querySelector('.header__toggle');
            Header.menu = Header.el.querySelector('.header__menu');
            Header.links = Header.el.querySelectorAll('.header__nav a');
            Header.prevWidth = Header.el.getBoundingClientRect().width;

            /* Animate menu slide in with page scroll */
            window.addEventListener('load', Header.animateScroll)
            window.addEventListener('scroll', e => Header.animateScroll(e))

            /* Animate toggle */
            if (Header.toggle && Header.menu) {
                Header.initialColor = getComputedStyle(Header.toggle).backgroundColor

                Header.toggle.addEventListener('click', () => {
                    Header.el.classList.toggle('open');
                    document.body.classList.toggle('menu-open');

                    // Closing
                    if (Header.isOpened) {
                        Header.animateToggleToBurger()
                        Modal.animateSlideOut(Header.menu, document.querySelector('.header__menu-ctn'))
                        Header.toggle.ariaExpanded = false
                        window.removeEventListener('keydown', Header.trapFocus)
                    }
                    //Opening
                    else if (!Header.el.classList.contains('hide')) {
                        Header.animateToggleToCross()
                        Modal.animateSlideIn(Header.menu, document.querySelector('.header__menu-ctn'))
                        Header.toggle.ariaExpanded = true
                        window.addEventListener('keydown', Header.trapFocus)
                    }

                    if (window.innerWidth <= 840 && document.body.classList.contains('white')
                        || window.innerWidth <= 840 && document.body.classList.contains('green')) {
                        Logo.updateLogo()
                    }
                    Header.isArrow = false
                    Header.isOpened = !Header.isOpened
                });

                // Force close on resize (prevents position bugs)
                // Force logo refresh on resize
                window.addEventListener('resize', () => {
                    if(Header.prevWidth !== Header.el.getBoundingClientRect().width){
                        Header.prevWidth = Header.el.getBoundingClientRect().width;

                        if (Header.isOpened) {
                            Header.animateToggleToBurger()
                            Header.isOpened = !Header.isOpened
                            Header.el.classList.remove('open');
                            document.body.classList.remove('menu-open');
                        }
                        Modal.animateSlideOut(Header.menu, document.querySelector('.header__menu-ctn'))
                        Header.isArrow = false
                        Logo.updateLogo()
                    }
                })

                document.addEventListener('click', (e) => {
                    if (document.querySelector('.header__menu') === e.target && !Header.toggle.contains(e.target)) {
                        Modal.animateSlideOut(Header.menu, document.querySelector('.header__menu-ctn'))
                        Header.animateToggleToBurger()
                        Header.isArrow = false
                        Header.isOpened = false
                    }
                })

                // Animate burger to arrow
                Header.toggle.addEventListener('mouseenter', () => {
                    if (!Header.isOpened) {
                        Header.animateToggleToArrow()
                        Header.isArrow = true
                    }
                })

                /* Animate arrow to burger */
                Header.toggle.addEventListener('mouseleave', () => {
                    if (!Header.isOpened && Header.isArrow) {
                        Header.animateToggleToBurger()
                        Header.isArrow = false
                    }
                })
            }

            /* Animate link hover */
            if (Header.links.length > 0) {

                Header.links.forEach(link => {
                    const div = document.createElement('div')
                    div.classList.add('menu-arrow')
                    div.innerHTML = `
                        <div class="diagonal"></div>
                        <div class="line-bottom"></div>
                        <div class= "line-right"></div>
                    `
                    link.appendChild(div)

                    let diagonal = link.querySelector('.diagonal')
                    let lineBottom = link.querySelector('.line-bottom')
                    let lineRight = link.querySelector('.line-right')

                    const tl = Header.createLinktimeline(link, diagonal, lineBottom, lineRight)

                    link.addEventListener('mouseenter', () => {
                        if (!window.greenmode) tl.play()
                    })
                    link.addEventListener('mouseleave', () => {
                        if (!window.greenmode) tl.reverse()
                    })
                })
            }
        }
    },
    /* = => X */
    animateToggleToCross: function () {
        gsap.to(Header.toggle, {
            backgroundColor: Header.green
        })
        gsap.to('.header__toggle .top', {
            width: 30,
            rotate: 45,
            translateX: -3,
            translateY: 18
        })
        gsap.to('.header__toggle .middle', {
            width: 30,
            rotate: 45,
            translateX: 1,
            translateY: -2,
        })
        gsap.to('.header__toggle .bottom', {
            width: 30,
            rotate: -45,
            translateY: -10
        })
    },
    /* X => = */
    animateToggleToBurger: function () {
        gsap.to(Header.toggle, {
            backgroundColor: Header.initialColor
        }),
            gsap.to('.header__toggle .top', {
                width: 28,
                rotate: 0,
                translateX: 0,
                translateY: 0
            })
        gsap.to('.header__toggle .middle', {
            width: 28,
            rotate: 0,
            translateX: 0,
            translateY: 0
        })
        gsap.to('.header__toggle .bottom', {
            width: 28,
            rotate: 0,
            translateX: 0,
            translateY: 0
        })


    },
    animateToggleToArrow: function () {
        if (!window.greenmode) {
            gsap.to(Header.toggle, {
                backgroundColor: Header.green
            })
            gsap.to('.header__toggle .top', {
                rotate: 90,
                translateY: 19,
                width: 24,
            })
            gsap.to('.header__toggle .middle', {
                rotate: 45,
                width: 32,
                translateY: -2,
                translateX: 1
            })
            gsap.to('.header__toggle .bottom', {
                width: 24
            })
        }
    },
    animateScroll: function (e) {
        if(e && Header.isOpened){
            e.preventDefault();
        } else {
            if (window.scrollY < 100) {
                Header.el.classList.remove('hide')
                Logo.el.classList.remove('hide')
            }
            else if (Header.prevScroll !== null) {
                if (Header.prevScroll < window.scrollY - 10) {
                    Header.el.classList.add('hide')
                    Logo.el.classList.add('hide')
                }
                else if (Header.prevScroll > window.scrollY + 10) {
                    Header.el.classList.remove('hide')
                    Logo.el.classList.remove('hide')
                    Header.el.classList.add('scrolled')
                    Logo.el.classList.add('scrolled')
                }
            }
            Header.prevScroll = window.scrollY
        }
    },
    createLinktimeline: function (link, diagonal, lineBottom, lineRight) {
        const tl = gsap.timeline({
            paused: true,
            defaults: {
                duration: 0.3,
                ease: "power2.inOut"
            }
        })

        tl.to(link, {
            color: '#00A76B',
            duration: 0.2,
        })
        tl.to([diagonal, lineBottom, lineRight], {
            backgroundColor: '#00A76B'
        }, '<')
        tl.to(diagonal, {
            width: '100%'
        })
        tl.to([lineRight, lineBottom], {
            autoAlpha: 1,
        }, '-=0.2')
        tl.to(lineBottom, {
            rotate: '0deg',
        }, '<')
        tl.to(lineRight, {
            rotate: '90deg',
        }, '<')

        return tl;
    },
    trapFocus: function (e) {

        let isTabPressed = e.key === "Tab" || e.keyCode === 9;
    
        if (!isTabPressed) return;
            
        const focusableElts = Header.menu.querySelectorAll(
          'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
        );
        
        const firstFocusableElt = focusableElts[0];
        const lastFocusableElt = focusableElts[focusableElts.length - 1];
    
        // Back tab
        if (e.shiftKey) {
          if (document.activeElement === Header.toggle) {
            lastFocusableElt.focus();
            e.preventDefault();
          }
          if (document.activeElement === firstFocusableElt) {
            Header.toggle.focus();
            e.preventDefault();
          }
        }
        // Forward tab
        else {
          if (document.activeElement === Header.toggle) {
            firstFocusableElt.focus();
            e.preventDefault();
          }
          if (document.activeElement === lastFocusableElt) {
            Header.toggle.focus();
            e.preventDefault();
          }
        }
      },
};

export default Header;
